<template>
	<div id="newCenter">
		<div class="banner">
			<el-image class="background" :src="banner" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<div class="cont">
					<p>新闻中心</p>
					<b>项目周期：2024年3月-2025年4月</b>
					<b>指导发起单位：中国初级卫生保健基金会</b>
					<b>执行单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
				</div>
			</div>
		</div>
		<div class="document">
			<!-- 文章 -->
			<el-table :data="tableData" style="width: 100%" @row-click="pageToDetails">
				<el-table-column>
					<template slot-scope="scope">
						{{ $moment(scope.row.updateTime).format('YYYY.MM.DD') }}
						<el-divider direction="vertical"></el-divider>
						{{scope.row.title}}
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange"
			:current-page.sync="pageNum" :page-size="pageSize" :hide-on-single-page="true"></el-pagination>


	</div>
</template>

<script>
	import {
		news,
		getConfig, // 首页获取logo banner 流程图  
	} from '../../request/api'
	export default {
		name: 'newCenter',
		components: {},
		data() {
			return {
				pageSize: 12,
				total: 0,
				pageNum: 1,
				tableData: [],
				banner: '',
			}
		},
		created() {
			this.getnews();
			this.getConfigFun();
		},
		mounted() {},
		methods: {
			getnews() {
				let data = {
					size: this.pageSize,
					current: this.pageNum
				}
				news(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.records;
						this.total = res.data.total;
					}
				})
			},

			handleCurrentChange(val) {
				this.getnews();
			},

			// 跳转到详情页面
			pageToDetails(row) {
				console.log(row)
				this.$router.push({
					name: "ContentDetails",
					query: {
						id: row.id
					}
				})
			},
			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#newCenter {
		display: flex;
		flex-direction: column;

		.banner {
			min-height: 60vh;
			position: relative;

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				position: absolute;
				width: 1200px;
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding-bottom: 150px;

				.cont {
					display: flex;
					flex-direction: column;

					p {
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
					}

					b {
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						color: #000;
						opacity: .6;
						line-height: 1.5;

						img {
							height: 36px;
						}
					}
				}
			}
		}

		.document {
			width: 1200px;
			margin: auto;
			margin-top: 40px;

			::v-deep .el-table {
				.el-table__row {
					.cell {
						font-size: 20px;
						padding: 5px 10px;

						.el-divider {
							margin: 0 30px;
							background-color: #606266;

						}
					}
				}
			}
		}

		.el-pagination {
			text-align: center;
			margin-top: 40px;
		}

		::v-deep .el-table {
			.el-table__cell {
				padding: 16px;

				.cell {
					font-size: 16px;
					display: flex;
					align-items: center;

					.el-divider {
						height: 24px;
					}
				}
			}
		}
	}
</style>